import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { EventsService } from 'src/app/Services/events.service';
import { Events } from './Events';
import { Message } from 'primeng/api';
import { GuidGenerator } from 'src/app/Models/GuidGenerator';
import { FileUpload } from 'src/app/Models/FileUpload';
import { UploadFileServiceService } from 'src/app/Services/upload-file-service.service';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css']
})
export class EventsComponent implements OnInit {

  promise: Promise<void>;
  msgs: Message[] = [];
  eventsList: Events[] = [];
  eventsListReveresed: Events[] = [];
  editItemEvents: any;
  EventsForm: any;
  addEventsFormStatus: boolean;

  currentFileUploadEventsBanner: FileUpload;
  progressEventsBanner: { percentage: number } = { percentage: 0 }
  selectedEventsBanner: any;
  currentFileUploadEventsListingImage: FileUpload;
  progressEventsListingImage: { percentage: number } = { percentage: 0 }
  selectedEventsListingImage: any;


  ngOnInit(): void {
    this.LoadEvents();
   // this.LoadEventsReversed();
  }


  constructor(
    private formBuilder: FormBuilder,
    private eventsService: EventsService,
    private fileUploadService: UploadFileServiceService) {

  }

  LoadEvents() {
    this.eventsService.GetAllEvents()
      .subscribe(
        (news: Events[]) => {
          this.eventsList = news;
        },
        (error: any) => {
          alert(error);
        })
  }



  buildEventsForm(programData) {
    const page = programData;
    this.EventsForm = this.formBuilder.group({
      EventsTitle: [page ? page.EventsTitle : '', Validators.required],
      ShortDescription: [page ? page.ShortDescription : ''],
      Description: [page ? page.Description : ''],
      EventsBanner: [page ? page.EventsBanner : ''],
      EventsListingImage: [page ? page.EventsListingImage : ''],
      Active: [page ? page.Active : false],
      EventsId: [page ? page.EventsId : ''],
      CreatedDate: [page ? page.CreatedDate : ''],
      CreatedDateString: [page ? page.CreatedDateString : ''],
      IsTopEvent: [page ? page.IsTopEvent : false],
      EventDate:[page ? page.EventDate : ''],
      EventDateString:[page ? page.EventDateString : '']
    });
  }

  findSelectedItemIndex(item): number {
    return this.eventsList.indexOf(item);
  }

  toggleAddEventsForm() {
    this.editItemEvents = null;
    this.EventsForm = this.formBuilder.group({
      EventsTitle: ['', Validators.required],
      ShortDescription: '',
      Description: '',
      EventsBanner: '',
      EventsListingImage: '',
      Active: false,
      EventsId: '',
      CreatedDate: '',
      CreatedDateString: '',
      IsTopEvent:false,
      EventDate:'',
      EventDateString:''
    });
    this.addEventsFormStatus = true;
  }



  editEventsForm(eventData) {
   
    if (eventData.EventDateString != null) {
      eventData.EventDate = new Date(eventData.EventDateString);
    }
    debugger
    this.editItemEvents = eventData;
    this.addEventsFormStatus = true;

    if (this.addEventsFormStatus) {
      this.buildEventsForm(this.editItemEvents)
    }
  }


  deleteEventsForm(categoryPages) {
    let index = this.findSelectedItemIndex(categoryPages);
    if (index >= 0) {
      this.eventsList.splice(index, 1);
      this.eventsService.SaveEvents(this.eventsList);
    }
  }

  onSubmit(formData) {
    debugger
    let EventsArray = [];
    formData.value.EventDateString = this.convertDate(formData.value.EventDate);
    if (this.currentFileUploadEventsBanner != null && this.currentFileUploadEventsBanner.url != null) {
      if (formData.value.EventsBanner != null && formData.value.EventsBanner != "") {
        this.fileUploadService.removeFireBaseImage(formData.value.EventsBanner);
        formData.value.EventsBanner = '';
      }
      formData.value.EventsBanner = this.currentFileUploadEventsBanner.url;
    }
    if (this.currentFileUploadEventsListingImage != null && this.currentFileUploadEventsListingImage.url != null) {
      if (formData.value.EventsListingImage != null && formData.value.EventsListingImage != "") {
        this.fileUploadService.removeFireBaseImage(formData.value.EventsListingImage);
        formData.value.EventsListingImage = '';
      }
      formData.value.EventsListingImage = this.currentFileUploadEventsListingImage.url;

    }
    if (this.eventsList && this.eventsList.length > 0) {
      EventsArray = [...this.eventsList];
    }
    if (this.editItemEvents) {
      EventsArray[this.findSelectedItemIndex(this.editItemEvents)] = formData.value;
    } else {
      formData.value.EventsId = GuidGenerator.GenerateGuid();
      let date = Date.now();
      formData.value.CreatedDate = date;
      formData.value.CreatedDateString = this.getDate(date);
      EventsArray.push(formData.value);
    }

    if (!this.eventsList) {
      this.eventsList = [];
    }
    console.log(this.eventsList);
     this.promise = this.eventsService.SaveEvents(EventsArray);
     this.promise.then(_ => this.msgs.push({ severity: 'success', summary: 'Success Message', detail: 'Saved' }))
       .catch(err => this.msgs.push({ severity: 'error', summary: 'Error Message', detail: err }));
    this.toggleAddEventsForm['EventsArray'] = EventsArray;
    this.editItemEvents = null;
    this.toggleAddEventsForm();
    this.addEventsFormStatus = false;
    this.progressEventsBanner.percentage = 0;
    this.progressEventsListingImage.percentage = 0;
    this.currentFileUploadEventsBanner = null;
    this.currentFileUploadEventsListingImage = null;
    this.LoadEvents();
  }



  UploadEventsBanner(event) {
    const file = event.target.files.item(0);
    if (file.type.match('image.*')) {
      this.selectedEventsBanner = event.target.files;
      this.EventsBanner();
    } else {
      alert('invalid format!');
    }
  }

  EventsBanner() {
    const file = this.selectedEventsBanner.item(0)
    this.selectedEventsBanner = undefined;
    this.currentFileUploadEventsBanner = new FileUpload(file);
    this.fileUploadService.pushFileToStorageGeneric(this.currentFileUploadEventsBanner, this.progressEventsBanner,"Events")
  }


  UploadEventsThumbnail(event) {
    const file = event.target.files.item(0);
    if (file.type.match('image.*')) {
      this.selectedEventsListingImage = event.target.files;
      this.EventsListingImage();
    } else {
      alert('invalid format!');
    }
  }

  EventsListingImage() {
    const file = this.selectedEventsListingImage.item(0)
    this.selectedEventsListingImage = undefined;
    this.currentFileUploadEventsListingImage = new FileUpload(file);
    this.fileUploadService.pushFileToStorageGeneric(this.currentFileUploadEventsListingImage, this.progressEventsListingImage,"Events")
  }


  getDate(date) {
    var today = new Date(date);
    var day = today.getDate() + "";
    var month = (today.getMonth() + 1) + "";
    var year = today.getFullYear() + "";
    var hour = today.getHours() + "";
    var minutes = today.getMinutes() + "";
    var seconds = today.getSeconds() + "";

    day = this.checkZero(day);
    month = this.checkZero(month);
    year = this.checkZero(year);
    hour = this.checkZero(hour);
    minutes = this.checkZero(minutes);
    seconds = this.checkZero(seconds);

    let date1 = (day + "/" + month + "/" + year + " " + hour + ":" + minutes + ":" + seconds);
    return date1;
  }


  checkZero(data) {
    if (data.length == 1) {
      data = "0" + data;
    }
    return data;
  }

  convertDate(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }


}

