import { Injectable } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';

@Injectable({
  providedIn: 'root'
})
export class SubCategoryService {

  
  baseSubCategoryPath:string = "/SubCategory/";
  constructor(private fireBase: AngularFireDatabase) { }

  GetAllSubCategory() {
    return this.fireBase.object(this.baseSubCategoryPath).valueChanges();
  }

  SaveSubCategory(data:any){
    return this.fireBase.object(this.baseSubCategoryPath).set(data);
  }
}
