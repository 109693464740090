import { Injectable } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';

@Injectable({
  providedIn: 'root'
})
export class DirectoryService {

  baseDirectoryPath:string = "/Directory/";
  constructor(private fireBase: AngularFireDatabase) { }

  GetAllDirectories() {
    return this.fireBase.object(this.baseDirectoryPath).valueChanges();
  }

  SaveDirectory(data:any){
    return this.fireBase.object(this.baseDirectoryPath).set(data);
  }
}
