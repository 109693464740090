export class FileUpload {
    
    $key: string;
    name: string;
    url: string;
    file: File;
  
    constructor(file: File) {
      this.file = file;
    }
  }

  export class BarCodeUpload {
    
    $key: string;
    name: string;
    url: string;
    
  }

  
  export class UploadThumbnail {
    name: string;
    url: string;
    
  }