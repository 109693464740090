import { Component, OnInit } from '@angular/core';
import { FileUpload } from 'src/app/Models/FileUpload';
import { Message } from 'primeng/api';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ConfigureService } from 'src/app/Services/configure.service';
import { UploadFileServiceService } from 'src/app/Services/upload-file-service.service';

@Component({
    selector: 'app-configure',
    templateUrl: './configure.component.html',
    styleUrls: ['./configure.component.css']
})
export class ConfigureComponent implements OnInit {
    promise: Promise<void>;
    msgs: Message[] = [];
    message;
    configurationData;
    configurationForm: FormGroup;
    loading = false;
    currentFileUploadEmailLogo: FileUpload;
    currentFileUploadBannerLogo: FileUpload;
    progressEmailLogo: { percentage: number } = { percentage: 0 }
    selectedEmailLogo: any;
    showWifiDetails: boolean = false;
    progressBannerLogo: { percentage: number } = { percentage: 0 }
    selectedBannerLogo: any;

    constructor(private configurationService: ConfigureService,
        private formBuilder: FormBuilder,
        private fileUploadService: UploadFileServiceService) {
        this.configurationData = this.configurationService.GetConfiguration().subscribe(element => {
            this.configurationData = element;
            this.buildFormGroup(this.configurationData);
            this.loading = true;
        });
    }

    ngOnInit() {
        this.loading = false;
    }

    buildFormGroup(configurationData) {
        let data = configurationData;
        console.log(data);
        this.loading = true;
        this.configurationForm = this.formBuilder.group({
            FacebookUrl: [data ? data.FacebookUrl : ''],
            LinkdinUrl: [data ? data.LinkdinUrl : ''],
            TwitterUrl: [data ? data.TwitterUrl : ''],
            Privacypolicy: [data ? data.Privacypolicy : ''],
            TermsandCondition: [data ? data.TermsandCondition : ''],
            CompanyEmailLogo: [data ? data.CompanyEmailLogo : ''],
            BannerLogo: [data ? data.BannerLogo : ''],
            BannerTitle: [data ? data.BannerTitle : ''],
            BannerText: [data ? data.BannerText : '']
        });
    }

    onNewSubmit(formData) {
        if (this.currentFileUploadEmailLogo != null && this.currentFileUploadEmailLogo.url != null) {
            if (formData.value.CompanyEmailLogo != null && formData.value.CompanyEmailLogo != "") {
                this.fileUploadService.removeFireBaseImage(formData.value.CompanyEmailLogo);
                formData.value.CompanyEmailLogo = '';
            }
            formData.value.CompanyEmailLogo = this.currentFileUploadEmailLogo.url;
        }
        if (this.currentFileUploadBannerLogo != null && this.currentFileUploadBannerLogo.url != null) {
            if (formData.value.BannerLogo != null && formData.value.BannerLogo != "") {
                this.fileUploadService.removeFireBaseImage(formData.value.BannerLogo);
                formData.value.BannerLogo = '';
            }
            formData.value.BannerLogo = this.currentFileUploadBannerLogo.url;
        }

        this.promise = this.configurationService.SaveConfiguration(formData.value);
        this.promise.then(_ => this.msgs.push({ severity: 'success', summary: 'Success Message', detail: 'Saved' }))
            .catch(err => this.msgs.push({ severity: 'error', summary: 'Error Message', detail: err }));
        this.loading = true;
        this.progressEmailLogo.percentage = 0;
        this.currentFileUploadEmailLogo = null;
        this.currentFileUploadBannerLogo = null;
        this.buildFormGroup(formData.value);
    }



    UploadCompanyEmailLogo(event) {
        debugger
        const file = event.target.files.item(0);
        if (file.type.match('image.*')) {
            this.selectedEmailLogo = event.target.files;
            this.CompanyEmailLogo();
        } else {
            alert('invalid format!');
        }
    }
    UploadCompanyBannerLogo(event) {
        console.log(event);
        debugger
        const file = event.target.files.item(0);
        console.log(file);
        console.log(file.type.match('image.*'));
        if (file.type.match('image.*')) {
            this.selectedBannerLogo = event.target.files;
            this.CompanyBannerLogo();
        } else {
            alert('invalid format!');
        }
    }

    CompanyBannerLogo() {
        const file = this.selectedBannerLogo.item(0)
        this.selectedBannerLogo = undefined;
        this.currentFileUploadBannerLogo = new FileUpload(file);
        this.fileUploadService.pushFileToStorage(this.currentFileUploadBannerLogo, this.progressBannerLogo)
    }
    CompanyEmailLogo() {
        const file = this.selectedEmailLogo.item(0)
        this.selectedEmailLogo = undefined;
        this.currentFileUploadEmailLogo = new FileUpload(file);
        this.fileUploadService.pushFileToStorage(this.currentFileUploadEmailLogo, this.progressEmailLogo)
    }
}
