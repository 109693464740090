import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { GuidGenerator } from 'src/app/Models/GuidGenerator';
import { CategoryService } from 'src/app/Services/category.service';
import { Category } from './category';
import { Message, SelectItem } from 'primeng/api';
import { FileUpload } from 'src/app/Models/FileUpload';
import { UploadFileServiceService } from 'src/app/Services/upload-file-service.service';
import { BigMouthHelper } from 'src/app/Models/BigMouthHelper';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit {

  promise: Promise<void>;
  msgs: Message[] = [];
  categories: Category[] = [];
  editItemCatgeory: any;
  CategoryForm: any;
  addCategoryFormStatus: boolean;
  selectedFiles: any;
  currentFileUpload: FileUpload;
  progress: { percentage: number } = { percentage: 0 }
  redirectKeyWordList: SelectItem[] = [];
  IconSource: string = '';
  ngOnInit(): void {
    this.LoadCategory();
    this.LoadRedirectKeywordList();
  }


  constructor(
    private formBuilder: FormBuilder,
    private categoryService: CategoryService, private fileUploadService: UploadFileServiceService) { }

  LoadCategory() {
    this.categoryService.GetAllCategory()
      .subscribe(
        (categories: Category[]) => {
          this.categories = categories;
        },
        (error: any) => {
          alert(error);
        })
  }
  LoadRedirectKeywordList() {
    this.redirectKeyWordList = BigMouthHelper.RedirectKeywordList()
  }

  buildCategoryForm(programData) {
    const page = programData;
    this.CategoryForm = this.formBuilder.group({
      CategoryId: [page ? page.CategoryId : ''],
      Title: [page ? page.Title : '', Validators.required],
      Active: [page ? page.Active : false],
      IconSource: [page ? page.IconSource : ''],
      ColorCode: [page ? page.ColorCode : ''],
      showUnderWhatsOn: [page ? page.showUnderWhatsOn : false],
      ShowOnHeader: [page ? page.ShowOnHeader : false],
      NavigateToPage: [page ? page.NavigateToPage : ''],
      RedirectKeyword: [page ? page.RedirectKeyword : '', Validators.required],
    });
  }

  findSelectedItemIndex(item): number {
    return this.categories.indexOf(item);
  }

  toggleAddCategoryForm() {
    this.editItemCatgeory = null;
    this.CategoryForm = this.formBuilder.group({
      CategoryId: '',
      Title: ['', Validators.required],
      Active: false,
      ColorCode: '',
      IconSource: '',
      showUnderWhatsOn: false,
      ShowOnHeader:false,
      NavigateToPage: '',
      RedirectKeyword: ['', Validators.required]
    });
    this.addCategoryFormStatus = true;
  }



  editCategoryForm(eventData) {
    this.editItemCatgeory = eventData;
    this.addCategoryFormStatus = true;
    if (eventData.IconSource != '') {
      this.IconSource = eventData.IconSource;
    }
    if (this.addCategoryFormStatus) {
      this.buildCategoryForm(this.editItemCatgeory)
    }
  }


  deleteCategoryForm(categoryPages) {
    let index = this.findSelectedItemIndex(categoryPages);
    if (index >= 0) {
      this.categories.splice(index, 1);
      this.categoryService.SaveCategory(this.categories);
    }
  }

  ////Image Upload
  selectFile(event) {
    const file = event.target.files.item(0);
    if (file.type.match('image.*')) {
      this.selectedFiles = event.target.files;
      this.upload();
    } else {
      alert('invalid format!');
    }
  }

  upload() {
    const file = this.selectedFiles.item(0)
    this.selectedFiles = undefined
    this.currentFileUpload = new FileUpload(file);
    this.fileUploadService.pushFileToStorage(this.currentFileUpload, this.progress)
  }

  onSubmit(formData) {
    let categoryArray = [];
    if (this.currentFileUpload != null && this.currentFileUpload.url != null) {
      if (formData.value.IconSource != null && formData.value.IconSource != "") {
        this.fileUploadService.removeFireBaseImage(formData.value.IconSource);
        formData.value.IconSource = '';
      }
      formData.value.IconSource = this.currentFileUpload.url;
    }
    if (this.categories && this.categories.length > 0) {
      categoryArray = [...this.categories];
    }
    if (this.editItemCatgeory) {
      categoryArray[this.findSelectedItemIndex(this.editItemCatgeory)] = formData.value;
    } else {
      formData.value.CategoryId = GuidGenerator.GenerateGuid();
      categoryArray.push(formData.value);
    }

    if (!this.categories) {
      this.categories = [];
    }

    this.promise = this.categoryService.SaveCategory(categoryArray);
    console.log(categoryArray);
    this.promise.then(_ => this.msgs.push({ severity: 'success', summary: 'Success Message', detail: 'Saved' }))
      .catch(err => this.msgs.push({ severity: 'error', summary: 'Error Message', detail: err }));
    this.toggleAddCategoryForm['categoryArray'] = categoryArray;
    this.editItemCatgeory = null;
    this.toggleAddCategoryForm();
    this.addCategoryFormStatus = false;
    this.currentFileUpload = null;
    this.IconSource = "";
  }



}
