import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NewsService } from 'src/app/Services/news.service';
import { News } from './news';
import { Message } from 'primeng/api';
import { GuidGenerator } from 'src/app/Models/GuidGenerator';
import { FileUpload, UploadThumbnail } from 'src/app/Models/FileUpload';
import { UploadFileServiceService } from 'src/app/Services/upload-file-service.service';
import { ThumbnailGenerateService } from 'src/app/Services/thumbnail-generate.service';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {

  promise: Promise<void>;
  msgs: Message[] = [];
  newsList: News[] = [];
  newsListReveresed: News[] = [];
  editItemNews: any;
  NewsForm: any;
  addNewsFormStatus: boolean;

  currentFileUploadNewsBanner: FileUpload;
  progressNewsBanner: { percentage: number } = { percentage: 0 }
  selectedNewsBanner: any;
  currentFileUploadNewsListingImage: FileUpload;
  progressNewsListingImage: { percentage: number } = { percentage: 0 }
  selectedNewsListingImage: any;
  showVideoDiv: boolean;
  //video upload
  currentVideoFileUpload: FileUpload;
  currentThumbnailFileUpload: UploadThumbnail;
  selectedVideoFiles: any;
  selectedVideoFiles1: any;
  videoprogress: { percentage: number } = { percentage: 0 }
  thumbnailData: string;
  ngOnInit(): void {
    this.LoadNews();
  }


  constructor(
    private formBuilder: FormBuilder,
    private newsService: NewsService,
    private fileUploadService: UploadFileServiceService,
    private thumbnailService: ThumbnailGenerateService) {

  }

  LoadNews() {
    this.newsService.GetAllNews()
      .subscribe(
        (news: News[]) => {
          this.newsList = news;
        },
        (error: any) => {
          alert(error);
        })
  }
  LoadNewsReversed() {
    this.newsService.GetAllNews()
      .subscribe(
        (news: News[]) => {
          this.newsListReveresed = news.reverse();
        },
        (error: any) => {
          alert(error);
        })
  }


  buildNewsForm(programData) {
    const page = programData;
    this.NewsForm = this.formBuilder.group({
      NewsTitle: [page ? page.NewsTitle : '', Validators.required],
      ShortDescription: [page ? page.ShortDescription : ''],
      Description: [page ? page.Description : ''],
      NewsBanner: [page ? page.NewsBanner : ''],
      NewsThumbnail: [page ? page.NewsThumbnail : ''],
      NewsListingImage: [page ? page.NewsListingImage : ''],
      Active: [page ? page.Active : false],
      NewsId: [page ? page.NewsId : ''],
      CreatedDate: [page ? page.CreatedDate : ''],
      CreatedDateString: [page ? page.CreatedDateString : ''],
      IsVideoAvailable: [page ? page.IsVideoAvailable : false],
      NewsVideoUrl: [page ? page.NewsVideoUrl : ''],
      IsTopNews: [page ? page.IsTopNews : false]
    });
  }

  findSelectedItemIndex(item): number {
    return this.newsList.indexOf(item);
  }

  toggleAddNewsForm() {
    this.editItemNews = null;
    this.NewsForm = this.formBuilder.group({
      NewsTitle: ['', Validators.required],
      ShortDescription: '',
      Description: '',
      NewsBanner: '',
      NewsThumbnail: '',
      NewsListingImage: '',
      Active: false,
      NewsId: '',
      CreatedDate: '',
      CreatedDateString: '',
      IsVideoAvailable: false,
      NewsVideoUrl: '',
      IsTopNews: false
    });
    this.addNewsFormStatus = true;
    this.showVideoDiv = false;
    this.thumbnailData = "";
  }



  editNewsForm(eventData) {
    this.showVideoDiv = false;
    this.editItemNews = eventData;
    this.addNewsFormStatus = true;
    if (eventData.NewsVideoUrl != undefined && eventData.NewsVideoUrl != "") {
      this.showVideoDiv = true;
      this.thumbnailData = eventData.NewsThumbnail;
    }

    if (this.addNewsFormStatus) {
      this.buildNewsForm(this.editItemNews)
    }
  }


  deleteNewsForm(categoryPages) {
    let index = this.findSelectedItemIndex(categoryPages);
    if (index >= 0) {
      this.newsList.splice(index, 1);
      this.newsService.SaveNews(this.newsList);
    }
  }

  onSubmit(formData) {
    let newsArray = [];

    if (formData.value.IsVideoAvailable == false) {
      if (formData.value.NewsVideoUrl != null && formData.value.NewsVideoUrl != "") {
        this.fileUploadService.removeFireBaseImage(formData.value.NewsVideoUrl);
        formData.value.NewsVideoUrl = '';
      }
      if (formData.value.NewsThumbnail != null && formData.value.NewsThumbnail != "") {
        this.fileUploadService.removeFireBaseImage(formData.value.NewsThumbnail);
        formData.value.NewsThumbnail = '';
      }
    }
    if (this.currentFileUploadNewsBanner != null && this.currentFileUploadNewsBanner.url != null) {
      if (formData.value.NewsBanner != null && formData.value.NewsBanner != "") {
        this.fileUploadService.removeFireBaseImage(formData.value.NewsBanner);
        formData.value.NewsBanner = '';
      }
      formData.value.NewsBanner = this.currentFileUploadNewsBanner.url;
    }
    if (this.currentFileUploadNewsListingImage != null && this.currentFileUploadNewsListingImage.url != null) {
      if (formData.value.NewsListingImage != null && formData.value.NewsListingImage != "") {
        this.fileUploadService.removeFireBaseImage(formData.value.NewsListingImage);
        formData.value.NewsListingImage = '';
      }
      formData.value.NewsListingImage = this.currentFileUploadNewsListingImage.url;
    }

    if (this.currentVideoFileUpload != null && this.currentVideoFileUpload.url != null) {
      if (formData.value.NewsVideoUrl != null && formData.value.NewsVideoUrl != "") {
        this.fileUploadService.removeFireBaseImage(formData.value.NewsVideoUrl);
        formData.value.NewsVideoUrl = '';
      }
      formData.value.NewsVideoUrl = this.currentVideoFileUpload.url;
    }

    if (this.currentThumbnailFileUpload != null && this.currentThumbnailFileUpload.url != null) {
      if (formData.value.NewsThumbnail != null && formData.value.NewsThumbnail != "") {
        this.fileUploadService.removeFireBaseImage(formData.value.NewsThumbnail);
        formData.value.NewsThumbnail = '';
      }
      formData.value.NewsThumbnail = this.currentThumbnailFileUpload.url;
    }


    if (this.newsList && this.newsList.length > 0) {
      newsArray = [...this.newsList];
    }
    if (this.editItemNews) {
      newsArray[this.findSelectedItemIndex(this.editItemNews)] = formData.value;
    } else {
      formData.value.NewsId = GuidGenerator.GenerateGuid();
      let date = Date.now();
      formData.value.CreatedDate = date;
      formData.value.CreatedDateString = this.getDate(date);
      newsArray.push(formData.value);
    }

    if (!this.newsList) {
      this.newsList = [];
    }
    console.log(this.newsList);
    this.promise = this.newsService.SaveNews(newsArray);
    this.promise.then(_ => this.msgs.push({ severity: 'success', summary: 'Success Message', detail: 'Saved' }))
      .catch(err => this.msgs.push({ severity: 'error', summary: 'Error Message', detail: err }));
    this.toggleAddNewsForm['newsArray'] = newsArray;
    this.editItemNews = null;
    this.toggleAddNewsForm();
    this.addNewsFormStatus = false;
    this.progressNewsBanner.percentage = 0;
    this.progressNewsListingImage.percentage = 0;
    this.currentFileUploadNewsBanner = null;
    this.currentFileUploadNewsListingImage = null;
    this.currentVideoFileUpload = null;
    this.currentThumbnailFileUpload = null;
    this.showVideoDiv = false;
    this.LoadNews();
  }



  UploadNewsBanner(event) {
    const file = event.target.files.item(0);
    if (file.type.match('image.*')) {
      this.selectedNewsBanner = event.target.files;
      this.NewsBanner();
    } else {
      alert('invalid format!');
    }
  }

  NewsBanner() {
    const file = this.selectedNewsBanner.item(0)
    this.selectedNewsBanner = undefined;
    this.currentFileUploadNewsBanner = new FileUpload(file);
    this.fileUploadService.pushFileToStorageGeneric(this.currentFileUploadNewsBanner, this.progressNewsBanner, "News")
  }


  UploadNewsThumbnail(event) {
    const file = event.target.files.item(0);
    if (file.type.match('image.*')) {
      this.selectedNewsListingImage = event.target.files;
      this.NewsListingBanner();
    } else {
      alert('invalid format!');
    }
  }

  public GenerateThumbnail(): void {
    this.thumbnailService.promptForVideo().then(videoFile => {
      this.selectedVideoFiles = videoFile;
      this.selectedVideoFiles1 = videoFile;
      this.uploadVideo();
      return this.thumbnailService.generateThumbnail(videoFile);
    }).then(thumbnailData => {
      this.thumbnailData = thumbnailData;
      let abc1 = thumbnailData.split(';');
      let imageExtension = abc1[0].replace('data:', '');
      let imageBase64 = abc1[1].replace('base64,', '');
      let fileName = this.selectedVideoFiles1.name.split(".")[0];
      this.currentThumbnailFileUpload = new UploadThumbnail();
      this.fileUploadService.pushFileToStorageWithBae64(imageBase64, fileName, imageExtension, this.currentThumbnailFileUpload);
    })
  }



  //video upload
  uploadVideo() {
    const file = this.selectedVideoFiles;
    this.selectedVideoFiles = undefined
    this.currentVideoFileUpload = new FileUpload(file);
    this.fileUploadService.pushVideoFileToStorage(this.currentVideoFileUpload, this.videoprogress)
  }

  NewsListingBanner() {
    const file = this.selectedNewsListingImage.item(0)
    this.selectedNewsListingImage = undefined;
    this.currentFileUploadNewsListingImage = new FileUpload(file);
    this.fileUploadService.pushFileToStorageGeneric(this.currentFileUploadNewsListingImage, this.progressNewsListingImage, "News")
  }


  getDate(date) {
    var today = new Date(date);
    var day = today.getDate() + "";
    var month = (today.getMonth() + 1) + "";
    var year = today.getFullYear() + "";
    var hour = today.getHours() + "";
    var minutes = today.getMinutes() + "";
    var seconds = today.getSeconds() + "";

    day = this.checkZero(day);
    month = this.checkZero(month);
    year = this.checkZero(year);
    hour = this.checkZero(hour);
    minutes = this.checkZero(minutes);
    seconds = this.checkZero(seconds);

    let date1 = (day + "/" + month + "/" + year + " " + hour + ":" + minutes + ":" + seconds);
    return date1;
  }


  checkZero(data) {
    if (data.length == 1) {
      data = "0" + data;
    }
    return data;
  }
  IsVideoAvailable(event: any) {
    this.showVideoDiv = event;
  }

}

