// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  firebase: {

    //Live Database
    apiKey: "AIzaSyCKoEOeH1BbRsE3Zu3xx26GoT5mBdKqim4",
    authDomain: "bigmouth-35ed8.firebaseapp.com",
    databaseURL: "https://bigmouth-35ed8.firebaseio.com",
    projectId: "bigmouth-35ed8",
    storageBucket: "bigmouth-35ed8.appspot.com",
    messagingSenderId: "81542486966",
    appId: "1:81542486966:web:1b4ac6699393ae12047f17",
    measurementId: "G-5DCC85RJW5"


  }
};
