import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { DashboardComponent } from './demo/view/dashboard.component';
import { SampleDemoComponent } from './demo/view/sampledemo.component';
import { FormsDemoComponent } from './demo/view/formsdemo.component';
import { DataDemoComponent } from './demo/view/datademo.component';
import { PanelsDemoComponent } from './demo/view/panelsdemo.component';
import { OverlaysDemoComponent } from './demo/view/overlaysdemo.component';
import { MenusDemoComponent } from './demo/view/menusdemo.component';
import { MessagesDemoComponent } from './demo/view/messagesdemo.component';
import { MiscDemoComponent } from './demo/view/miscdemo.component';
import { EmptyDemoComponent } from './demo/view/emptydemo.component';
import { ChartsDemoComponent } from './demo/view/chartsdemo.component';
import { FileDemoComponent } from './demo/view/filedemo.component';
import { UtilsDemoComponent } from './demo/view/utilsdemo.component';
import { DocumentationComponent } from './demo/view/documentation.component';
import { AppMainComponent } from './app.main.component';
import { AppNotfoundComponent } from './pages/app.notfound.component';
import { AppErrorComponent } from './pages/app.error.component';
import { AppAccessdeniedComponent } from './pages/app.accessdenied.component';
import { AppLoginComponent } from './pages/app.login.component';
import { LoginComponent } from './Components/login/login.component';
import { AuthGuard } from './auth-guard.service';
import { CategoryComponent } from './components/category/category.component';
import { MenusComponent } from './Components/menus/menus.component';
import { NewsComponent } from './Components/news/news.component';
import { EventsComponent } from './Components/Events/Events.component';
import { OffersComponent } from './Components/Offers/Offers.component';
import { SubCategoryComponent } from './Components/sub-category/sub-category.component';
import { DirectoryComponent } from './components/directory/directory.component';
import { ConfigureComponent } from './components/configure/configure.component';

export const routes: Routes = [

    { path: '', redirectTo: '/login-component', pathMatch: 'full' },
    { path: 'login-component', component: LoginComponent },
    {
        path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard],
        children: [
            { path: '',  redirectTo: '/dashboard', pathMatch: 'full' },
            { path: 'category', component: CategoryComponent, canActivate: [AuthGuard] },
            { path: 'menus', component: MenusComponent, canActivate: [AuthGuard] },
            { path: 'news', component: NewsComponent, canActivate: [AuthGuard] },
            { path: 'events', component: EventsComponent, canActivate: [AuthGuard] },
            { path: 'offers', component: OffersComponent, canActivate: [AuthGuard] },
            { path: 'cuisines', component: SubCategoryComponent, canActivate: [AuthGuard] },
            { path: 'directories', component: DirectoryComponent, canActivate: [AuthGuard] },
            { path: 'configuration', component: ConfigureComponent, canActivate: [AuthGuard] },
        ]
    },

    {path: 'error', component: AppErrorComponent},
    {path: 'accessdenied', component: AppAccessdeniedComponent},
    {path: '404', component: AppNotfoundComponent},    
    {path: '**', redirectTo: '/404'},

];

export const AppRoutes: ModuleWithProviders = RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'});
