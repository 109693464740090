import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import {  Message, SelectItem } from 'primeng/primeng';
import { MenuService } from 'src/app/Services/menu.service';
import { UploadFileServiceService } from 'src/app/Services/upload-file-service.service';
import { FileUpload } from 'src/app/Models/FileUpload';
import { GuidGenerator } from 'src/app/Models/GuidGenerator';
import { CategoryService } from 'src/app/Services/category.service';

@Component({
  selector: 'app-menus',
  templateUrl: './menus.component.html',
  styleUrls: ['./menus.component.css']
})
export class MenusComponent implements OnInit {

  loading: boolean;

  promise: Promise<void>
  menuData;
  editItemMenu;
  MenuForm: FormGroup;
  isNewMenu = false;
  private addMenuFormStatus;
  msgs: Message[] = [];
  IconSource: string = '';
  selectedFiles: any;
  currentFileUpload: FileUpload;
  progress: { percentage: number } = { percentage: 0 }
  showCatgory: boolean;
  showPages: boolean;
  
  constructor(private menuService: MenuService,
     private formBuilder: FormBuilder,
     private fileUploadService: UploadFileServiceService) {
    this.loadPageData();
  }

  loadPageData() {
    this.loading = false;
    this.menuData = this.menuService.GetAllMenu().subscribe(pageData => {
      this.menuData = pageData;
      if (this.menuData.IconSource != '') {
        this.IconSource = this.menuData.IconSource;
      }
      this.buildPageForm(this.menuData);
    });
  }

  buildFormGroup(checkedInUserAccessMileData) {
    let data = checkedInUserAccessMileData;
    this.loading = true;  
  }
  ngOnInit() {
    this.loading = false;
  }

  //#region Fr Menu
  buildPageForm(menuData) {
    const page = menuData;
    this.MenuForm = this.formBuilder.group({
      Title: [page ? page.Title : '', Validators.required],
      IconSource: [page ? page.IconSource : ''],
      TargetType: [page ? page.TargetType : '', Validators.required],
      TextColor: [page ? page.TextColor : ''],
      MenuType: [page ? page.MenuType : ''],
      SelectedItem: [page ? page.SelectedItem : false],
      IsActive: [page ? page.IsActive : false],
      DisplayOrder: [page ? page.DisplayOrder : 0],
      MenuId:[page ? page.MenuId : ''],
      Badge: {
        ShowBadge: false,
        BageCount: 0
      }
    });
  }


  editMenuForm(menuData) {
    this.isNewMenu = false;
    this.editItemMenu = menuData;
    if (menuData.IconSource != '') {
      this.IconSource = menuData.IconSource;
    }
  
    this.addMenuFormStatus = true;
    if (this.addMenuFormStatus) {
      this.buildPageForm(this.editItemMenu)
    }
  }

  toggleAddMenuForm() {
    this.showCatgory=true;
    this.showPages=false;
    this.isNewMenu = true;
    this.editItemMenu = null;
    this.MenuForm = this.formBuilder.group({
      Title: ['', Validators.required],
      IconSource: [''],
      TargetType: ['', Validators.required],
      TextColor: [''],
      MenuType: [''],
      MenuId:'',
      SelectedItem: [false],
      IsActive: [false],
      DisplayOrder: [0],
      Badge: {
        ShowBadge: false,
        BageCount: 0
      }
    });
    this.addMenuFormStatus = true;
this.IconSource="";
  }

  ////Image Upload
  selectFile(event) {
    const file = event.target.files.item(0);
    if (file.type.match('image.*')) {
      this.selectedFiles = event.target.files;
      this.upload();
    } else {
      alert('invalid format!');
    }
  }

  upload() {
    const file = this.selectedFiles.item(0)
    this.selectedFiles = undefined
    this.currentFileUpload = new FileUpload(file);
    this.fileUploadService.pushFileToStorage(this.currentFileUpload, this.progress)
  }

  onNewMenuSubmit(formData) {
    if (this.currentFileUpload != null && this.currentFileUpload.url != null) {
      if (formData.value.IconSource != null && formData.value.IconSource != "") {
        this.fileUploadService.removeFireBaseImage(formData.value.IconSource);
        formData.value.IconSource = '';
      }
        formData.value.IconSource = this.currentFileUpload.url;
    }    
    if (formData.value != undefined && formData.value != '') {
      formData.value.Badge = {
        ShowBadge: false,
        BageCount: 0
      };
    }
    if(formData.value.MenuId==undefined ||formData.value.MenuId==""){
      formData.value.MenuId = GuidGenerator.GenerateGuid();
    }
    let index = this.findSelectedItemIndex(this.editItemMenu);
    let eventDays = [];
    if (this.menuData && this.menuData.length > 0) {
      eventDays = [...this.menuData];
    }
    if (this.editItemMenu) {
      eventDays[this.findSelectedItemIndex(this.editItemMenu)] = formData.value;
    } else {
      formData.value.MenuId = GuidGenerator.GenerateGuid();
      eventDays.push(formData.value);
    }
    if (!this.menuData) {
      this.menuData = {};
    }

    this.promise = this.menuService.SaveMenu(eventDays);
    this.promise.then(_ => this.msgs.push({ severity: 'success', summary: 'Success Message', detail: 'Saved' }))
      .catch(err => this.msgs.push({ severity: 'error', summary: 'Error Message', detail: err }));
    this.toggleAddMenuForm['eventDays'] = eventDays;
    this.editItemMenu = null;
    this.toggleAddMenuForm();
    this.addMenuFormStatus = false;
    this.currentFileUpload =null;
    this.IconSource="";
  }

  findSelectedItemIndex(item): number {
        return this.menuData.indexOf(item);
  }
  deleteMenuForm(event) {
    let index = this.findSelectedItemIndex(event);    
    if (index >= 0) {
      this.menuData.splice(index, 1);
      this.menuService.SaveMenu(this.menuData);
    }
  }

  RadioButtonChange(value: any) {
    if (value == "Category") {
      this.showCatgory = true;
      this.showPages = false;
    }
    if (value == "Page") {
      this.showPages = true;
      this.showCatgory = false;
    }
  }

  //#endregion



}
