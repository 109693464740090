import { Injectable } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';

@Injectable({
  providedIn: 'root'
})
export class OffersService {

  baseOffersPath:string = "/Offers/";
  constructor(private fireBase: AngularFireDatabase) { }

  GetAllOffers() {
    return this.fireBase.object(this.baseOffersPath).valueChanges();
  }

  SaveOffers(data:any){
    return this.fireBase.object(this.baseOffersPath).set(data);
  }

}
