import { Component, OnInit } from '@angular/core';
import { Message, SelectItem } from 'primeng/primeng';
import { SubCategory } from './SubCategory';
import { SubCategoryService } from 'src/app/Services/sub-category.service';
import { FormBuilder, Validators } from '@angular/forms';
import { CategoryService } from 'src/app/Services/category.service';
import { Category } from '../category/category';
import { GuidGenerator } from 'src/app/Models/GuidGenerator';


@Component({
    selector: 'app-sub-category',
    templateUrl: './sub-category.component.html',
    styleUrls: ['./sub-category.component.css']
})
export class SubCategoryComponent implements OnInit {
    promise: Promise<void>;
    msgs: Message[] = [];
    subCategories: SubCategory[] = [];
    editItemSubCatgeory: any;
    SubCategoryForm: any;
    addSubCategoryFormStatus: boolean;
    categories: SelectItem[] = [];
    constructor(
        private formBuilder: FormBuilder,
        private subCategoryService: SubCategoryService,
        private categoryService: CategoryService) { }

    ngOnInit() {
        this.LoadCategory();
        this.LoadSubCategory();
    }

    LoadSubCategory() {
        this.subCategoryService.GetAllSubCategory()
            .subscribe(
                (categories: SubCategory[]) => {
                    this.subCategories = categories;
                },
                (error: any) => {
                    alert(error);
                })
    }

    LoadCategory() {
        this.categoryService.GetAllCategory()
            .subscribe(
                (categories: Category[]) => {
                    categories = categories.filter(x => x.showUnderWhatsOn == false);
                    categories.forEach(element => {
                        let value = {
                            label: element.Title,
                            value: element.CategoryId
                        }
                        this.categories.push(value);
                    });

                },
                (error: any) => {
                    alert(error);
                })
    }

    buildSubCategoryForm(programData) {
        const page = programData;
        this.SubCategoryForm = this.formBuilder.group({
            CategoryId: [page ? page.CategoryId : []],
            SubCategoryId: [page ? page.SubCategoryId : ''],
            Title: [page ? page.Title : '', Validators.required],
            Active: [page ? page.Active : false],

        });
    }

    findSelectedItemIndex(item): number {
        return this.subCategories.indexOf(item);
    }

    toggleAddSubCategoryForm() {
        this.editItemSubCatgeory = null;
        this.SubCategoryForm = this.formBuilder.group({
            CategoryId: [],
            SubCategoryId: '',
            Title: ['', Validators.required],
            Active: false,

        });
        this.addSubCategoryFormStatus = true;
    }



    editSubCategoryForm(eventData) {
        this.editItemSubCatgeory = eventData;
        this.addSubCategoryFormStatus = true;

        if (this.addSubCategoryFormStatus) {
            this.buildSubCategoryForm(this.editItemSubCatgeory)
        }
    }


    deleteSubCategoryForm(categoryPages) {
        let index = this.findSelectedItemIndex(categoryPages);
        if (index >= 0) {
            this.subCategories.splice(index, 1);
            this.subCategoryService.SaveSubCategory(this.subCategories);
        }
    }


    onSubmit(formData) {
        debugger;
        let subCategoryArray = [];

        if (this.subCategories && this.subCategories.length > 0) {
            subCategoryArray = [...this.subCategories];
        }
        if (this.editItemSubCatgeory) {
            subCategoryArray[this.findSelectedItemIndex(this.editItemSubCatgeory)] = formData.value;
        } else {
            formData.value.SubCategoryId = GuidGenerator.GenerateGuid();
            subCategoryArray.push(formData.value);
        }

        if (!this.subCategories) {
            this.subCategories = [];
        }

        this.promise = this.subCategoryService.SaveSubCategory(subCategoryArray);
        console.log(subCategoryArray);
        this.promise.then(_ => this.msgs.push({ severity: 'success', summary: 'Success Message', detail: 'Saved' }))
            .catch(err => this.msgs.push({ severity: 'error', summary: 'Error Message', detail: err }));
        this.toggleAddSubCategoryForm['subCategoryArray'] = subCategoryArray;
        this.editItemSubCatgeory = null;
        this.toggleAddSubCategoryForm();
        this.addSubCategoryFormStatus = false;

    }

}
