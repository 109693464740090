import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { EventsService } from 'src/app/Services/events.service';

import { Message } from 'primeng/api';
import { GuidGenerator } from 'src/app/Models/GuidGenerator';
import { FileUpload, BarCodeUpload } from 'src/app/Models/FileUpload';
import { UploadFileServiceService } from 'src/app/Services/upload-file-service.service';
import $ from "jquery";
import { OffersService } from 'src/app/Services/offers.service';
import { Offers } from './Offers';
@Component({
  selector: 'app-offers',
  templateUrl: './offers.component.html',
  styleUrls: ['./offers.component.css']
})
export class OffersComponent implements OnInit {
  barCode = '';
  elementType = "img";
  currentBarCode: BarCodeUpload;
  progressOfferBarCode: { percentage: number } = { percentage: 0 }

  promise: Promise<void>;
  msgs: Message[] = [];
  OffersList: Offers[] = [];
  editItemOffers: any;
  OffersForm: any;
  addOffersFormStatus: boolean;
  showBarCode: boolean = false;
  currentFileUploadOfferBanner: FileUpload;
  progressOfferBanner: { percentage: number } = { percentage: 0 }
  selectedOffersBanner: any;


  ngOnInit(): void {
    this.LoadOffers();
   }

  constructor(private fileUploadService: UploadFileServiceService, private offersService: OffersService, private formBuilder: FormBuilder) { }



  LoadOffers() {
    this.offersService.GetAllOffers()
      .subscribe(
        (offers: Offers[]) => {
          this.OffersList = offers;
        },
        (error: any) => {
          alert(error);
        })
  }

  buildOffersForm(programData) {
    const page = programData;
    this.OffersForm = this.formBuilder.group({
      OfferTitle: [page ? page.OfferTitle : '', Validators.required],
      ShortDescription: [page ? page.ShortDescription : ''],
      OfferDiscount: [page ? page.OfferDiscount : ''],
      Active: [page ? page.Active : false],
      OfferId: [page ? page.OfferId : ''],
      CreatedDate: [page ? page.CreatedDate : ''],
      CreatedDateString: [page ? page.CreatedDateString : ''],
      OfferBannerCode: [page ? page.OfferBannerCode : ''],
      IsTopOffer: [page ? page.IsTopOffer : false],
      OfferImage: [page ? page.OfferImage : '']
    });
  }

  findSelectedItemIndex(item): number {
    return this.OffersList.indexOf(item);
  }

  toggleAddOffersForm() {
    this.currentBarCode = null;
    this.editItemOffers = null;
    this.OffersForm = this.formBuilder.group({
      OfferTitle: ['', Validators.required],
      ShortDescription: '',
      OfferDiscount: '',
      Active: false,
      OfferId: '',
      CreatedDate: '',
      CreatedDateString: '',
      OfferBannerCode: '',
      IsTopOffer:false,
      OfferImage:''
    });
    this.addOffersFormStatus = true;
    this.barCode="";
  }



  editOffersForm(eventData) {
    this.editItemOffers = eventData;
    this.addOffersFormStatus = true;
    if (this.addOffersFormStatus) {
      this.buildOffersForm(this.editItemOffers)
    }
  }


  deleteOffersForm(offer) {
    let index = this.findSelectedItemIndex(offer);
    if (index >= 0) {
      this.OffersList.splice(index, 1);
      this.offersService.SaveOffers(this.OffersList);
    }
  }

  onSubmit(formData) {
    setTimeout(() => {
      
   
    let offersArray = [];
    console.log(this.currentBarCode);
    if (this.currentBarCode != null && this.currentBarCode.url != null) {
      if (formData.value.OfferBannerCode != null && formData.value.OfferBannerCode != "") {
        this.fileUploadService.removeFireBaseImage(formData.value.OfferBannerCode);
        formData.value.OfferBannerCode = '';
      }
      formData.value.OfferBannerCode = this.currentBarCode.url;
    }

    if (this.currentFileUploadOfferBanner != null && this.currentFileUploadOfferBanner.url != null) {
      if (formData.value.OfferImage != null && formData.value.OfferImage != "") {
        this.fileUploadService.removeFireBaseImage(formData.value.OfferImage);
        formData.value.OfferImage = '';
      }
      formData.value.OfferImage = this.currentFileUploadOfferBanner.url;
    }


    if (this.OffersList && this.OffersList.length > 0) {
      offersArray = [...this.OffersList];
    }
    if (this.editItemOffers) {
      offersArray[this.findSelectedItemIndex(this.editItemOffers)] = formData.value;
    } else {
      formData.value.OfferId = GuidGenerator.GenerateGuid();
      let date = Date.now();
      formData.value.CreatedDate = date;
      formData.value.CreatedDateString = this.getDate(date);
      offersArray.push(formData.value);
    }

    if (!this.OffersList) {
      this.OffersList = [];
    }
    console.log(this.OffersList);
    this.promise = this.offersService.SaveOffers(offersArray);
    this.promise.then(_ => this.msgs.push({ severity: 'success', summary: 'Success Message', detail: 'Saved' }))
      .catch(err => this.msgs.push({ severity: 'error', summary: 'Error Message', detail: err }));
    this.toggleAddOffersForm['offersArray'] = offersArray;
    this.editItemOffers = null;
    this.toggleAddOffersForm();
    this.addOffersFormStatus = false;
    this.progressOfferBarCode.percentage = 0;
    this.currentBarCode = null;
    this.currentFileUploadOfferBanner = null;
    this.LoadOffers();
    this.barCode="";
  }, 3000);
  }



  getDate(date) {
    var today = new Date(date);
    var day = today.getDate() + "";
    var month = (today.getMonth() + 1) + "";
    var year = today.getFullYear() + "";
    var hour = today.getHours() + "";
    var minutes = today.getMinutes() + "";
    var seconds = today.getSeconds() + "";

    day = this.checkZero(day);
    month = this.checkZero(month);
    year = this.checkZero(year);
    hour = this.checkZero(hour);
    minutes = this.checkZero(minutes);
    seconds = this.checkZero(seconds);

    let date1 = (day + "/" + month + "/" + year + " " + hour + ":" + minutes + ":" + seconds);
    return date1;
  }


  checkZero(data) {
    if (data.length == 1) {
      data = "0" + data;
    }
    return data;
  }


  barCodeGenerate(formData) {
    if (formData.value.OfferTitle != "" && formData.value.OfferTitle != undefined) {
      setTimeout(() => {
        
        let abc = this.imageSrc();
        this.currentBarCode = new BarCodeUpload();
        if (abc != "") {
          let abc1 = abc.split(';');
          let imageExtension = abc1[0].replace('data:', '');
          let imageBase64 = abc1[1].replace('base64,', '');
         
          this.fileUploadService.pushBarCodeToStorageWithBae64(imageBase64, this.progressOfferBarCode, formData.value.OfferTitle, imageExtension, this.currentBarCode);
        }
      }, 2000);
   
    }
    else {
      this.msgs.push({ severity: 'error', summary: 'Error Message', detail: 'Offer Title cannot be null' })
    }
  }

  imageSrc(): string {
    let src = $('.barcode img').attr('src');
    // $('.barcode').children('img').map(function () {
    //   src = $(this).attr('src')
    // }).get()
    return src;
  }

  onOfferTitleChange(formData) {
    this.showBarCode = true;
    if(formData.value!=undefined && formData.value.OfferTitle!=undefined){
    //this.barCode = formData.value.OfferTitle;
    }
    else{
      alert("Enter Offer Title");
    }
  }

  UploadOfferBanner(event) {
    const file = event.target.files.item(0);
    if (file.type.match('image.*')) {
      this.selectedOffersBanner = event.target.files;
      this.OffersBanner();
    } else {
      alert('invalid format!');
    }
  }

  OffersBanner() {
    const file = this.selectedOffersBanner.item(0)
    this.selectedOffersBanner = undefined;
    this.currentFileUploadOfferBanner = new FileUpload(file);
    this.fileUploadService.pushFileToStorage(this.currentFileUploadOfferBanner, this.progressOfferBanner)
  }
}
