import { Injectable } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';

@Injectable({
  providedIn: 'root'
})
export class EventsService {

  baseEventsPath:string = "/Events/";
  constructor(private fireBase: AngularFireDatabase) { }

  GetAllEvents() {
    return this.fireBase.object(this.baseEventsPath).valueChanges();
  }

  SaveEvents(data:any){
    return this.fireBase.object(this.baseEventsPath).set(data);
  }

}
