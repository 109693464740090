import { FirebaseListObservable } from 'angularfire2/database-deprecated';
import { FileUpload, BarCodeUpload, UploadThumbnail } from './../Models/FileUpload';
import { Injectable } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';
import * as firebase from 'firebase';
import { GuidGenerator } from '../Models/GuidGenerator';



@Injectable()
export class UploadFileServiceService {
  isDeleted: boolean = false;

  private basePath = '/uploads';
  private baseVideoPath = '/videos';
  private basePathBarCode = '/barcode';
  private basePathThumbNail = '/thumbnail';
  fileUploads: FirebaseListObservable<FileUpload[]>;

  constructor(private db: AngularFireDatabase) { }

  pushFileToStorage(fileUpload: FileUpload, progress: { percentage: number }) {
    const storageRef = firebase.storage().ref();
    const uniqueGUID = GuidGenerator.GenerateGuid();
    let file = fileUpload.file.name.split('.');

    let filename = file[0] + uniqueGUID + "." + file[1];
    const uploadTask = storageRef.child(`${this.basePath}/${filename}`).put(fileUpload.file);

    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot) => {
      },
      (error) => {
      },
      () => {
        // success
        const snap = uploadTask.snapshot as firebase.storage.UploadTaskSnapshot
        progress.percentage = Math.round((snap.bytesTransferred / snap.totalBytes) * 100)
        fileUpload.url = uploadTask.snapshot.downloadURL
        fileUpload.name = filename;
      }
    );
  }

  
  pushFileToStorageGeneric(fileUpload: FileUpload, progress: { percentage: number },path:string) {
    const storageRef = firebase.storage().ref();
    const uniqueGUID = GuidGenerator.GenerateGuid();
    let file = fileUpload.file.name.split('.');
    path = "/" + path
    let filename = file[0] + uniqueGUID + "." + file[1];
    const uploadTask = storageRef.child(`${path}/${filename}`).put(fileUpload.file);

    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot) => {
      },
      (error) => {
      },
      () => {
        // success
        const snap = uploadTask.snapshot as firebase.storage.UploadTaskSnapshot
        progress.percentage = Math.round((snap.bytesTransferred / snap.totalBytes) * 100)
        fileUpload.url = uploadTask.snapshot.downloadURL
        fileUpload.name = filename;
      }
    );
  }


  pushVideoFileToStorage(fileUpload: FileUpload, progress: { percentage: number }) {
    const storageRef = firebase.storage().ref();
    const uniqueGUID = GuidGenerator.GenerateGuid();
    let file = fileUpload.file.name.split('.');
    debugger
    let filename = file[0].replace(" ", "-") + uniqueGUID + "." + file[1];
    const uploadTask = storageRef.child(`${this.baseVideoPath}/${filename}`).put(fileUpload.file);

    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot) => {
      },
      (error) => {
      },
      () => {
        // success
        const snap = uploadTask.snapshot as firebase.storage.UploadTaskSnapshot
        progress.percentage = Math.round((snap.bytesTransferred / snap.totalBytes) * 100)
        fileUpload.url = uploadTask.snapshot.downloadURL
        fileUpload.name = filename;
      }
    );


  }

  pushBarCodeToStorageWithBae64(base64: string, progress: { percentage: number }, fileName: string, fileextension: string, fileUpload: BarCodeUpload) {
    const storageRef = firebase.storage().ref();
    const uniqueGUID = GuidGenerator.GenerateGuid();
    let filename = fileName + uniqueGUID + "." + fileextension.split("/")[1];
    const uploadTask = storageRef.child(`${this.basePathBarCode}/${filename}`).putString(base64, 'base64', { contentType: fileextension });
    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot) => {
      },
      (error) => {
      },
      () => {
        // success
        const snap = uploadTask.snapshot as firebase.storage.UploadTaskSnapshot
        progress.percentage = Math.round((snap.bytesTransferred / snap.totalBytes) * 100)
        fileUpload.url = uploadTask.snapshot.downloadURL
        fileUpload.name = filename
        //this.saveFileData(fileUpload)

      }
    );

  }

  pushFileToStorageWithBae64(base64: string, fileName: string, fileextension: string, fileUpload: UploadThumbnail) {
    const storageRef = firebase.storage().ref();
    const uniqueGUID = GuidGenerator.GenerateGuid();
    let filename = fileName + uniqueGUID + "." + fileextension.split("/")[1];
    const uploadTask = storageRef.child(`${this.basePathThumbNail}/${filename}`).putString(base64, 'base64', { contentType: fileextension });
    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot) => {
      },
      (error) => {
      },
      () => {
        // success
      
        fileUpload.url = uploadTask.snapshot.downloadURL
        fileUpload.name = filename
        //this.saveFileData(fileUpload)

      }
    );

  }

  private saveFileData(fileUpload: FileUpload) {
    this.db.list(`${this.basePath}/`).push(fileUpload)
  }

  // getFileUploads(query = {}) {
  //   this.fileUploads = this.db.list(this.basePath, {
  //     query: query
  //   });
  //   return this.fileUploads
  // }

  deleteFileUpload(fileUpload: FileUpload) {
    this.deleteFileDatabase(fileUpload.$key)
      .then(() => {
        this.deleteFileStorage(fileUpload.name)
      })
      .catch(error => console.log(error))
  }

  private deleteFileDatabase(key: string) {
    return this.db.list(`${this.basePath}/`).remove(key)
  }

  private deleteFileStorage(name: string) {
    const storageRef = firebase.storage().ref()
    storageRef.child(`${this.basePath}/${name}`).delete()
  }


  removeFireBaseImage(url) {
    var desertRef = firebase.storage().refFromURL(url)
    
    // Delete the file
    return desertRef.delete()
  }


}
