import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import * as firebase from 'firebase';
import { AngularFireDatabase } from 'angularfire2/database';
import { Observable } from 'rxjs';
import { GuidGenerator } from 'src/app/Models/GuidGenerator';

@Component({
  selector: 'app-upload-task',
  templateUrl: './upload-task.component.html',
  styleUrls: ['./upload-task.component.css']
})
export class UploadTaskComponent implements OnInit,OnDestroy {
  ngOnDestroy(): void {
    this.downloadURL=null;
  }


  @Input() file: File;
  private baseGalleryPath = '/Gallery';
  percentage: Observable<number>;
  snapshot: Observable<any>;
  downloadURL: string;
  images: any[]=[];
  progress: { percentage: number } = { percentage: 0 }
  constructor(private db: AngularFireDatabase) { }

  ngOnInit() {
    this.startUpload();
    
  }

  startUpload() {

    const storageRef = firebase.storage().ref();
    let file = this.file.name.split('.');
    const uniqueGUID = GuidGenerator.GenerateGuid();
    let filename = file[0] + uniqueGUID + "." + file[1];
    const uploadTask = storageRef.child(`${this.baseGalleryPath}/${filename}`).put(this.file);
    //const uploadTask = storageRef.child(`${this.baseGalleryPath}/${this.file.name}`).put(this.file);
    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot) => {
      },
      (error) => {
      },
      () => {
        this.progress.percentage = 0;
        // success
        const snap = uploadTask.snapshot as firebase.storage.UploadTaskSnapshot
        //progress.percentage = Math.round((snap.bytesTransferred / snap.totalBytes) * 100)
        this.progress.percentage = Math.round((snap.bytesTransferred / snap.totalBytes) * 100)
        this.downloadURL = uploadTask.snapshot.downloadURL;
        this.progress = null
        this.images.push({source:this.downloadURL, alt:filename, title:filename});
      }
    );
  }

  isActive(snapshot) {
    return snapshot.state === 'running' && snapshot.bytesTransferred < snapshot.totalBytes;
  }

}


