import { SelectItem } from 'primeng/api';

export class BigMouthHelper {
    public static RedirectKeywordList()  {
        let list: SelectItem[] = [];
        list.push({ label: "-- Select --", value: null });
        list.push({ label: "Category page", value: "CategoryPage" });
        list.push({ label: "Events", value: "Events" });
        list.push({ label: "News", value: "News" });
        list.push({ label: "Offers", value: "Offers" });
        return list;
    }
}