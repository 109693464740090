import { Injectable } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';

@Injectable({
  providedIn: 'root'
})
export class ConfigureService {
  baseConfigurationPath:string = "/Configuration/";
  constructor(private fireBase: AngularFireDatabase) { }

  GetConfiguration() {
    return this.fireBase.object(this.baseConfigurationPath).valueChanges();
  }

  SaveConfiguration(data:any){
    return this.fireBase.object(this.baseConfigurationPath).set(data);
  }
}
