import { Injectable } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  baseCategoryPath:string = "/Category/";
  constructor(private fireBase: AngularFireDatabase) { }

  GetAllCategory() {
    return this.fireBase.object(this.baseCategoryPath).valueChanges();
  }

  SaveCategory(data:any){
    return this.fireBase.object(this.baseCategoryPath).set(data);
  }

}
