import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UploadFileServiceService } from 'src/app/Services/upload-file-service.service';
import { Directory } from './directory';
import { DirectoryService } from 'src/app/Services/directory.service';
import { ThumbnailGenerateService } from 'src/app/Services/thumbnail-generate.service';
import { SelectItem, Message } from 'primeng/api';
import { Category } from '../category/category';
import { CategoryService } from 'src/app/Services/category.service';
import { SubCategoryService } from 'src/app/Services/sub-category.service';
import { SubCategory } from '../sub-category/SubCategory';
import { FileUpload, UploadThumbnail, BarCodeUpload } from 'src/app/Models/FileUpload';
import { OffersService } from 'src/app/Services/offers.service';
import { Offers } from '../Offers/Offers';
import { GuidGenerator } from 'src/app/Models/GuidGenerator';
import $ from "jquery";
@Component({
  selector: 'app-directory',
  templateUrl: './directory.component.html',
  styleUrls: ['./directory.component.css']
})
export class DirectoryComponent implements OnInit {
    directories: Directory[] = [];
    allDirectories: Directory[] = [];
  @Output() dropped = new EventEmitter<FileList>();
  DirectoryForm: any;
  addDirectoryFormStatus: boolean;
  thumbnailData: string;
  categories: SelectItem[] = [];
  subCategories: SelectItem[] = [];
  offersList: SelectItem[] = [];
  showVideoDiv: boolean = false;
  promise: Promise<void>;
  msgs: Message[] = [];
  //video upload
  currentVideoFileUpload: FileUpload;
  currentThumbnailFileUpload: UploadThumbnail;
  selectedVideoFiles: any;
  selectedVideoFiles1: any;
  videoprogress: { percentage: number } = { percentage: 0 }

  //directory for listing Image
  currentDirectoryFileUpload: FileUpload;
  selectedDirectoryFiles: any;
  Directoryprogress: { percentage: number } = { percentage: 0 }

  //directory Banner Image
  currentDirectoryBannerImageFileUpload: FileUpload;
  selectedDirectoryBannerImageFiles: any;
  DirectoryBannerImageprogress: { percentage: number } = { percentage: 0 }

  //galleryUpload
  isHovering: boolean;
  galleryList: any;
  files: File[] = [];
  editDirectoryItem: any;
  selectePhotoUrl: any;
  displayDialog: boolean;
  // uploads: any[];
  // allPercentage: Observable<any>;
  // files: Observable<any>;

  OffersList: Offers[] = [];
  editItemOffers: any;
  OffersForm: any;
  addOffersFormStatus: boolean;
  showBarCode: boolean = false;
  currentFileUploadOfferBanner: FileUpload;
  progressOfferBanner: { percentage: number } = { percentage: 0 }
  selectedOffersBanner: any;
  barCode = '';
  elementType = "img";
  currentBarCode: BarCodeUpload;
  displayDialogOffer: boolean;
  progressOfferBarCode: { percentage: number } = { percentage: 0 }

  constructor(
    private formBuilder: FormBuilder,
    private categoryService: CategoryService,
    private directoryService: DirectoryService,
    private subCategoryService: SubCategoryService,
    private fileUploadService: UploadFileServiceService,
    private thumbnailService: ThumbnailGenerateService,
    private offersService: OffersService) { }

  ngOnInit(): void {
    this.LoadDirectories();
    this.LoadCategory();
    //this.LoadSubCategory();
    this.LoadOffers();
  }


  findInvalidControls() {
    const invalid = [];
    const controls = this.DirectoryForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    console.log(invalid)
    return invalid;
  }

 
  LoadDirectories() {
    this.directoryService.GetAllDirectories()
      .subscribe(
        (directories: Directory[]) => {
              this.directories = directories;
              this.allDirectories = directories;
        },
        (error: any) => {
          alert(error);
        })
  }
    fiterDirectoriesItem(value: string) {
        if (value) {
            if (this.directories && this.directories.length > 0) {
                
                this.directories = this.directories.filter(x => x.DirectoryTitle.toLowerCase().includes(value.toLowerCase()));
            }
        } else {
            this.directories=this.allDirectories;
        }
       


    }


  LoadCategory() {
    this.categoryService.GetAllCategory()
      .subscribe(
        (categories: Category[]) => {
          categories = categories.filter(x => x.showUnderWhatsOn == false);
          categories.forEach(element => {
            let value = {
              label: element.Title,
              value: element.CategoryId
            }
            this.categories.push(value);
          });

        },
        (error: any) => {
          alert(error);
        })
  }

  LoadOffers() {
    this.offersService.GetAllOffers()
      .subscribe(
        (offers: Offers[]) => {
              if (offers) {
                  offers.forEach(element => {
                      let value = {
                          label: element.OfferTitle,
                          value: element.OfferId
                      }
                      this.offersList.push(value);
                  });
              }
          

        },
        (error: any) => {
          alert(error);
        })
  }

  LoadSubCategory() {
    this.subCategoryService.GetAllSubCategory()
      .subscribe(
        (subCategoryService: SubCategory[]) => {

          subCategoryService.forEach(element => {
            let value = {
              label: element.Title,
              value: element.SubCategoryId
            }
            this.subCategories.push(value);
          });

        },
        (error: any) => {
          alert(error);
        })
  }

    onCategoryChanged(CategoryIds) {
        debugger;

        this.subCategoryService.GetAllSubCategory()
            .subscribe(
                (subCategoryService: SubCategory[]) => {
                    this.subCategories = [];
                    subCategoryService = subCategoryService.filter(x => { if (CategoryIds.value.includes(x.CategoryId)) return true; });
                    if (subCategoryService.length > 0) {
                        subCategoryService = subCategoryService.sort((n1, n2) => {
                            if (n1.Title > n2.Title) {
                                return 1;
                            }

                            if (n1.Title < n2.Title) {
                                return -1;
                            }

                            return 0;
                        });
                        subCategoryService.forEach(element => {
                            let value = {
                                label: element.Title,
                                value: element.SubCategoryId
                            }
                            this.subCategories.push(value);
                        });

                    }
                    

                   
                },
                (error: any) => {
                    alert(error);
                })
    }
  buildCategoryForm(programData) {
    const page = programData;
    this.offersList = programData.Offers;
    this.DirectoryForm = this.formBuilder.group({
      DirectoryOverview: [page ? page.DirectoryOverview : '', Validators.required],
      DirectoryId: [page ? page.DirectoryId : ''],
      ShortDesc: [page ? page.ShortDesc : '', Validators.required],
      DirectoryTitle: [page ? page.DirectoryTitle : '', Validators.required],
      IsVideoAvailable: [page ? page.IsVideoAvailable : false],
      DirectoryVideoUrl: [page ? page.DirectoryVideoUrl : ''],
      DirectoryVideoThumbnail: [page ? page.DirectoryVideoThumbnail : ''],
      DirectoryListingImage: [page ? page.DirectoryListingImage : ''],
      DirectoryAddress1: [page ? page.DirectoryAddress1 : '', Validators.required],
      DirectoryAddress2: [page ? page.DirectoryAddress2 : ''],
      DirectoryAddress3: [page ? page.DirectoryAddress3 : ''],
      DirectoryCity: [page ? page.DirectoryCity : '', Validators.required],
      DirectoryCounty: [page ? page.DirectoryCounty : ''],
      DirectoryCountry: [page ? page.DirectoryCountry : '', Validators.required],
      DirectoryPostCode: [page ? page.DirectoryPostCode : '', Validators.required],
      DirectoryWebSite: [page ? page.DirectoryWebSite : ''],
      DirectoryEmail: [page ? page.DirectoryEmail : ''],
      DirectoryMobileNumber: [page ? page.DirectoryMobileNumber : ''],
      DirectoryLandlineNumber: [page ? page.DirectoryLandlineNumber : ''],
      LatitudeAndLogitude: [page ? page.LatitudeAndLogitude : ''],
      CategoryId: [page ? page.CategoryId : []],
      SubCategoryId: [page ? page.SubCategoryId : []],
      OffersId: [page ? page.OffersId : []],
      Gallery: [page ? page.Gallery : []],
      DirectoryBannerImage: [page ? page.DirectoryBannerImage : []],
      TypeOfFood: [page ? page.TypeOfFood : ''],
      HoursandDays: [page ? page.HoursandDays : ''],
      //socialMedias
      Facebook: [page ? page.Facebook : ''],
      Instagram: [page ? page.Instagram : ''],
      Twitter: [page ? page.Twitter : ''],
      Pinterest: [page ? page.Pinterest : ''],
      Youtube: [page ? page.Youtube : ''],
      Tumblr: [page ? page.Tumblr : ''],
      GooglePlus: [page ? page.GooglePlus : ''],
      LinkedIn: [page ? page.LinkedIn : ''],
      Active: [page ? page.Active : false],
      BigMouthLoves:[page ? page.BigMouthLoves : ''],
      isBookingAvailable: [page ? page.isBookingAvailable : false],
      SEOName: [page ? page.SEOName : false],
      MetaTitle:[page ? page.MetaTitle : ''],
      MetaDescription:[page ? page.MetaDescription : ''],
      ShortTitle:[page ? page.ShortTitle : ''],
      Offers:[page ? page.Offers : []],
    });
  }

  toggleAddDirectoryForm() {
    this.editDirectoryItem = null;
    this.DirectoryForm = this.formBuilder.group({
      DirectoryOverview: ['', Validators.required],
      DirectoryId: '',
      ShortDesc: ['', Validators.required],
      DirectoryTitle: ['', Validators.required],
      IsVideoAvailable: '',
      DirectoryVideoUrl: '',
      DirectoryVideoThumbnail: '',
      DirectoryListingImage: '',
      DirectoryAddress1: ['', Validators.required],
      DirectoryAddress2: '',
      DirectoryAddress3: '',
      DirectoryCity: ['', Validators.required],
      DirectoryCounty: [''],
      DirectoryCountry: ['', Validators.required],
      DirectoryPostCode: ['', Validators.required],
      DirectoryWebSite: '',
      DirectoryEmail: '',
      DirectoryMobileNumber: '',
      DirectoryLandlineNumber: '',
      LatitudeAndLogitude: '',
      CategoryId: [],
      SubCategoryId: [],
      OffersId: [],
      Gallery: [],
      DirectoryBannerImage: '',
      TypeOfFood: '',
      HoursandDays: '',
      //socialMedias
      Facebook: '',
      Instagram: '',
      Twitter: '',
      Pinterest: '',
      Youtube: '',
      Tumblr: '',
      GooglePlus: '',
      LinkedIn: '',
      Active: false,
      BigMouthLoves:'',
      isBookingAvailable:false,
      SEOName:'',
      MetaTitle:'',
      MetaDescription:'',
      ShortTitle:'',
      Offers:[]
    });
    this.addDirectoryFormStatus = true;
  }


  editDirectoryForm(eventData) {
    this.editDirectoryItem = eventData;
    this.addDirectoryFormStatus = true;
    this.thumbnailData = eventData.DirectoryVideoThumbnail;
    // if (eventData.IconSource != '') {
    //   this.IconSource = eventData.IconSource;
    // }
    if (eventData.Gallery != undefined && eventData.Gallery.length > 0) {
      this.galleryList = eventData.Gallery;
    }
    this.buildCategoryForm(this.editDirectoryItem)
    this.OffersList = eventData.Offers;
    
  }

  
  deleteOffersForm(offer) {
    debugger
    // let index = this.findSelectedItemIndexOffer(offer);
    // if (index >= 0) {
    //   debugger 
    
    //  let aa =  this.OffersList.splice(index, 1);
    // }
    let index = this.OffersList.indexOf(offer);
    this.OffersList = this.OffersList.filter((val, i) => i != index);
    //this.OffersList =  this.OffersList;
  }

  public GenerateThumbnail(): void {
    this.thumbnailService.promptForVideo().then(videoFile => {
      this.selectedVideoFiles = videoFile;
      this.selectedVideoFiles1 = videoFile;
      this.uploadVideo();
      return this.thumbnailService.generateThumbnail(videoFile);
    }).then(thumbnailData => {
      this.thumbnailData = thumbnailData;
      let abc1 = thumbnailData.split(';');
      let imageExtension = abc1[0].replace('data:', '');
      let imageBase64 = abc1[1].replace('base64,', '');
      let fileName = this.selectedVideoFiles1.name.split(".")[0];
      this.currentThumbnailFileUpload = new UploadThumbnail();
      this.fileUploadService.pushFileToStorageWithBae64(imageBase64, fileName, imageExtension, this.currentThumbnailFileUpload);
    })
  }



  //video upload
  uploadVideo() {
    const file = this.selectedVideoFiles;
    this.selectedVideoFiles = undefined
    this.currentVideoFileUpload = new FileUpload(file);
    this.fileUploadService.pushVideoFileToStorage(this.currentVideoFileUpload, this.videoprogress)
  }

  ////Image Upload
    selectDirectoryImage(event) {
        debugger;
    const file = event.target.files.item(0);
    if (file.type.match('image.*')) {
      this.selectedDirectoryFiles = event.target.files;
      this.uploadDirectoryImage();
    } else {
      alert('invalid format!');
    }
  }

  uploadDirectoryImage() {
    const file = this.selectedDirectoryFiles.item(0)
    this.selectedDirectoryFiles = undefined
    this.currentDirectoryFileUpload = new FileUpload(file);
    this.fileUploadService.pushFileToStorage(this.currentDirectoryFileUpload, this.Directoryprogress)
  }


  ////Image Upload
  selectDirectoryBannerImage(event) {
   // const file = event.target.files.item(0);
    this.selectedDirectoryBannerImageFiles = event.target.files;
    this.uploadDirectoryBannerImage();
    //   if (file.type.match('image.*')) {
    //     debugger
    //       let reader = new FileReader();
    //       const img = new Image();
    //       img.src = window.URL.createObjectURL(file);
    //       reader.readAsDataURL(file);
    //       reader.onload = () => {
    //           const width = img.naturalWidth;
    //           const height = img.naturalHeight;
    //           window.URL.revokeObjectURL(img.src);
    //           if (width < 1404 && height < 519 ) {
    //               alert('invalid format!');
    //           } else {
    //               this.selectedDirectoryBannerImageFiles = event.target.files;
    //               this.uploadDirectoryBannerImage();
    //           }
    //       }
    // } else {
    //   alert('invalid format!');
    // }
  }

  uploadDirectoryBannerImage() {
    debugger
    const file = this.selectedDirectoryBannerImageFiles.item(0)
    this.selectedDirectoryBannerImageFiles = undefined
    this.currentDirectoryBannerImageFileUpload = new FileUpload(file);
    this.fileUploadService.pushFileToStorage(this.currentDirectoryBannerImageFileUpload, this.DirectoryBannerImageprogress)
  }

  galleryUpload(event) {
    for (let i = 0; i < event.target.files.length; i++) {
      this.files.push(event.target.files.item(i));
    }
    this.dropped.emit(event.target.files);
  }

    deleteDirectoryForm(data) {
        let directoryArray = [];
        this.directories = this.allDirectories;
        if (this.directories && this.directories.length > 0) {
            directoryArray = [...this.directories];
        }
        debugger
        if (directoryArray && directoryArray.length > 0) {
            let index = directoryArray.indexOf(data)
            if (index >= 0) {
                directoryArray.splice(index, 1);
                // this.menuService.SaveMenu(this.menuData);
                this.promise = this.directoryService.SaveDirectory(directoryArray);
                // console.log(directoryArray);
                this.promise.then(_ => this.msgs.push({ severity: 'success', summary: 'Success Message', detail: 'Deleted' }))
                    .catch(err => this.msgs.push({ severity: 'error', summary: 'Error Message', detail: err }));
            }
        }

        
    }

  onSubmit(formData) {
    let directoryArray = [];
    this.directories=this.allDirectories;
    if(this.OffersList!=undefined){
    formData.value.Offers = this.OffersList;
    }
    if (this.currentVideoFileUpload != null && this.currentVideoFileUpload.url != null) {
      if (formData.value.DirectoryVideoUrl != null && formData.value.DirectoryVideoUrl != "") {
        this.fileUploadService.removeFireBaseImage(formData.value.DirectoryVideoUrl);
        formData.value.DirectoryVideoUrl = '';
      }
      formData.value.DirectoryVideoUrl = this.currentVideoFileUpload.url;
      //formData.value.DirectoryVideoThumbnail = this.thumbnailData;
    }
    if(formData.value.DirectoryVideoUrl!='' && formData.value.DirectoryVideoUrl!=undefined){
      formData.value.IsVideoAvailable=true;

    }
    else{
      formData.value.IsVideoAvailable=false;
    }

    if (this.currentThumbnailFileUpload != null && this.currentThumbnailFileUpload.url != null) {
      if (formData.value.DirectoryVideoThumbnail != null && formData.value.DirectoryVideoThumbnail != "") {
        this.fileUploadService.removeFireBaseImage(formData.value.DirectoryVideoThumbnail);
        formData.value.DirectoryVideoThumbnail = '';
      }
      formData.value.DirectoryVideoThumbnail = this.currentThumbnailFileUpload.url;
      //formData.value.DirectoryVideoThumbnail = this.thumbnailData;
    }

    if (this.currentDirectoryFileUpload != null && this.currentDirectoryFileUpload.url != null) {
      if (formData.value.DirectoryListingImage != null && formData.value.DirectoryListingImage != "") {
        this.fileUploadService.removeFireBaseImage(formData.value.DirectoryListingImage);
        formData.value.DirectoryListingImage = '';
      }
      formData.value.DirectoryListingImage = this.currentDirectoryFileUpload.url;
    }

    if (this.currentDirectoryBannerImageFileUpload != null && this.currentDirectoryBannerImageFileUpload.url != null) {
      if (formData.value.DirectoryBannerImage != null && formData.value.DirectoryBannerImage != "") {
        this.fileUploadService.removeFireBaseImage(formData.value.DirectoryBannerImage);
        formData.value.DirectoryBannerImage = '';
      }
      formData.value.DirectoryBannerImage = this.currentDirectoryBannerImageFileUpload.url;
    }
    if (this.directories && this.directories.length > 0) {
      directoryArray = [...this.directories];
    }
    
 
    formData.value.Gallery = [];
    $(".gallery").each(function () {
      var img = $(this).find("img")
      let src = img.attr('src');
      formData.value.Gallery.push(src);
    })
    if (this.editDirectoryItem) {
      directoryArray[this.findSelectedItemIndex(this.editDirectoryItem)] = formData.value;
    } else {
      formData.value.DirectoryId = GuidGenerator.GenerateGuid();
      directoryArray.push(formData.value);
    }

    if (!this.categories) {
      this.categories = [];
    }

    this.promise = this.directoryService.SaveDirectory(directoryArray);
    console.log(directoryArray);
    this.promise.then(_ => this.msgs.push({ severity: 'success', summary: 'Success Message', detail: 'Saved' }))
      .catch(err => this.msgs.push({ severity: 'error', summary: 'Error Message', detail: err }));
    this.toggleAddDirectoryForm['directoryArray'] = directoryArray;
    this.editDirectoryItem = null;
    this.toggleAddDirectoryForm();
    this.addDirectoryFormStatus = false;
    this.currentDirectoryFileUpload = null;
    this.currentDirectoryBannerImageFileUpload = null;
    this.currentVideoFileUpload = null;
    this.currentThumbnailFileUpload= null;
    this.files = [];
    this.galleryList = [];
    this.thumbnailData="";
    this.displayDialog = false;
    this.displayDialogOffer=false;
    this.OffersList=[];
    this.addOffersFormStatus = false;
    this.OffersForm = this.formBuilder.group({
      OfferTitle: ['', Validators.required],
      ShortDescription: '',
      OfferDiscount: '',
      Active: false,
      OfferId: '',
      CreatedDate: '',
      CreatedDateString: '',
      OfferBannerCode: '',
      IsTopOffer:false,
      OfferImage:''
    });
  }

  findSelectedItemIndex(item): number {
    return this.directories.indexOf(item);
  }
  findSelectedItemIndexOffer(item): number {
    return this.OffersList.indexOf(item);
  }
  selectGallery(event: Event, url: any) {
    this.selectePhotoUrl = url;
    this.displayDialog = true;
    event.preventDefault();
  }

  deleteGallery(event: Event, url: any) {
    const athis = this;
    if (athis.DirectoryForm.value.Gallery != undefined && athis.DirectoryForm.value.Gallery != undefined) {

      athis.fileUploadService.removeFireBaseImage(url).then(function () {
        athis.msgs.push({ severity: 'success', summary: 'Success Message', detail: 'Deleted' });
        athis.DirectoryForm.value.Gallery.splice(athis.DirectoryForm.value.Gallery.indexOf(url), 1);
      }).catch(function (error) {
        console.log(error);
        athis.DirectoryForm.value.Gallery.splice(athis.DirectoryForm.value.Gallery.indexOf(url), 1);
       // athis.msgs.push({ severity: 'error', summary: 'Error Message', detail: error });
      });
    }
  }

  onDirectoryTitleChange(formData) {
    
    if(formData.value!=undefined && formData.value.DirectoryTitle!=undefined){
    
      formData.value.SEOName = formData.value.DirectoryTitle.replace(/ /g, "-").toLowerCase();
      this.buildCategoryForm(formData.value)
    }
    else{
      alert("Enter Offer Title");
    }
  }


  onDialogHide() {
    this.selectePhotoUrl = null;
  }

  AddOffer() {
    this.currentBarCode = null;
    this.editItemOffers = null;
    this.OffersForm = this.formBuilder.group({
      OfferTitle: ['', Validators.required],
      ShortDescription: '',
      OfferDiscount: '',
      Active: false,
      OfferId: '',
      CreatedDate: '',
      CreatedDateString: '',
      OfferBannerCode: '',
      IsTopOffer:false,
      OfferImage:''
    });
    this.addOffersFormStatus = true;
    this.barCode="";
    this.displayDialogOffer=true;
  }

  
  onOfferSubmit(formData) {
     setTimeout(() => {     
   debugger
    let offersArray = [];
    console.log(this.currentBarCode);
    if (this.currentBarCode != null && this.currentBarCode.url != null) {
      if (formData.value.OfferBannerCode != null && formData.value.OfferBannerCode != "") {
        this.fileUploadService.removeFireBaseImage(formData.value.OfferBannerCode);
        formData.value.OfferBannerCode = '';
      }
      formData.value.OfferBannerCode = this.currentBarCode.url;
    }

    if (this.currentFileUploadOfferBanner != null && this.currentFileUploadOfferBanner.url != null) {
      if (formData.value.OfferImage != null && formData.value.OfferImage != "") {
        this.fileUploadService.removeFireBaseImage(formData.value.OfferImage);
        formData.value.OfferImage = '';
      }
      formData.value.OfferImage = this.currentFileUploadOfferBanner.url;
    }


    if (this.OffersList && this.OffersList.length > 0) {
      offersArray = [...this.OffersList];
    }
    if (this.editItemOffers) {
      offersArray[this.findSelectedItemIndexOffer(this.editItemOffers)] = formData.value;
    } else {
      formData.value.OfferId = GuidGenerator.GenerateGuid();
      let date = Date.now();
      formData.value.CreatedDate = date;
      formData.value.CreatedDateString = this.getDate(date);
      offersArray.push(formData.value);
    }

    if (!this.OffersList) {
      this.OffersList = [];
    }

    this.OffersList = offersArray;
    console.log(this.OffersList);
    this.editItemOffers = null;
    this.addOffersFormStatus = false;
    this.progressOfferBarCode.percentage = 0;
    this.currentBarCode = null;
    this.currentFileUploadOfferBanner = null;
    this.LoadOffers();
    this.barCode="";
   }, 3000);
  }

  editOffersForm(eventData) {
    this.editItemOffers = eventData;
    this.addOffersFormStatus = true;
    if (this.addOffersFormStatus) {
      this.buildOffersForm(this.editItemOffers);
    }
    this.displayDialogOffer=true;
  }

  buildOffersForm(programData) {
    const page = programData;
    this.addOffersFormStatus
    this.OffersForm = this.formBuilder.group({
      OfferTitle: [page ? page.OfferTitle : '', Validators.required],
      ShortDescription: [page ? page.ShortDescription : ''],
      OfferDiscount: [page ? page.OfferDiscount : ''],
      Active: [page ? page.Active : false],
      OfferId: [page ? page.OfferId : ''],
      CreatedDate: [page ? page.CreatedDate : ''],
      CreatedDateString: [page ? page.CreatedDateString : ''],
      OfferBannerCode: [page ? page.OfferBannerCode : ''],
      IsTopOffer: [page ? page.IsTopOffer : false],
      OfferImage: [page ? page.OfferImage : '']
    });
  }

  getDate(date) {
    var today = new Date(date);
    var day = today.getDate() + "";
    var month = (today.getMonth() + 1) + "";
    var year = today.getFullYear() + "";
    var hour = today.getHours() + "";
    var minutes = today.getMinutes() + "";
    var seconds = today.getSeconds() + "";

    day = this.checkZero(day);
    month = this.checkZero(month);
    year = this.checkZero(year);
    hour = this.checkZero(hour);
    minutes = this.checkZero(minutes);
    seconds = this.checkZero(seconds);

    let date1 = (day + "/" + month + "/" + year + " " + hour + ":" + minutes + ":" + seconds);
    return date1;
  }


  checkZero(data) {
    if (data.length == 1) {
      data = "0" + data;
    }
    return data;
  }


  barCodeGenerate(formData) {
    if (formData.value.OfferTitle != "" && formData.value.OfferTitle != undefined) {
      setTimeout(() => {
        
        let abc = this.imageSrc();
        this.currentBarCode = new BarCodeUpload();
        if (abc != "") {
          let abc1 = abc.split(';');
          let imageExtension = abc1[0].replace('data:', '');
          let imageBase64 = abc1[1].replace('base64,', '');
         
          this.fileUploadService.pushBarCodeToStorageWithBae64(imageBase64, this.progressOfferBarCode, formData.value.OfferTitle, imageExtension, this.currentBarCode);
        }
      }, 2000);
   
    }
    else {
      this.msgs.push({ severity: 'error', summary: 'Error Message', detail: 'Offer Title cannot be null' })
    }
  }

  imageSrc(): string {
    let src = $('.barcode img').attr('src');
    // $('.barcode').children('img').map(function () {
    //   src = $(this).attr('src')
    // }).get()
    return src;
  }

  onOfferTitleChange(formData) {
    this.showBarCode = true;
    if(formData.value!=undefined && formData.value.OfferTitle!=undefined){
    //this.barCode = formData.value.OfferTitle;
    }
    else{
      alert("Enter Offer Title");
    }
  }

  UploadOfferBanner(event) {
    const file = event.target.files.item(0);
    if (file.type.match('image.*')) {
      this.selectedOffersBanner = event.target.files;
      this.OffersBanner();
    } else {
      alert('invalid format!');
    }
  }

  OffersBanner() {
    const file = this.selectedOffersBanner.item(0)
    this.selectedOffersBanner = undefined;
    this.currentFileUploadOfferBanner = new FileUpload(file);
    this.fileUploadService.pushFileToStorage(this.currentFileUploadOfferBanner, this.progressOfferBanner)
  }
}
